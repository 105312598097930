









import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ValidationSummaryComponent extends Vue {

  @Prop() private form: any;
  @Prop() private extraErrors: any;

  private validationErrors: Array<[string, string]> = [];

  private mounted() {
    if (this.extraErrors && this.extraErrors.length > 0) {
      for (const extraError of this.extraErrors) {
        this.validationErrors.push(extraError);
      }
    }
    this.getValidationErrors();
  }

  // Iterate form inputs and add the label and first item from the errorBucket to an array of tuples.
  private getValidationErrors() {
    if (this.form) {
      const data = (this.form._data as any);
      if (data) {
        const inputs = (data.inputs as any[]);
        if (inputs.length > 0) {
          for (const input of inputs) {
            const inputData = (input._data as any);
            if (inputData) {
              const inputDataErrors = (inputData.errorBucket as any[]);
              if (inputDataErrors.length > 0) {
                this.validationErrors.push([input.label, inputDataErrors[0]]);
              }
            }
          }
        }
      }
    }
  }
}
