



































import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '../components/BaseComponent';
import ErrorComponent from '../components/ErrorComponent.vue';

@Component({
  components: {
    ErrorComponent,
  },
})
export default class DeleteModalComponent extends BaseComponent {

  @Prop() private deleteType: any;

  private deleteText: string = '';
  private bProjectDeleting: boolean = false;

  private arrDeleteErrors: Error[] = [];

  protected mounted() {
    this.deleteText = '';
    this.bProjectDeleting = false;
  }

  private performDelete() {
    if (!this.bProjectDeleting) {
      if ((this.$refs.form as any).validate()) {
        this.bProjectDeleting = true;
        this.$emit('delete');
      }
    }
  }
}
