import { getShortList, getReferenceData, getDiversityReferenceData } from '../store/rest.service';
import { ResourceSearchResult, ReferenceData, ShortRecord, DiversityReferenceData, DiversityRefDataCategory, DiversityRefDataValue, DiversityDeclarationsMeta, DiversityDeclarationMeta } from '../store/models';
import { handleError } from '../lib/shared';

export enum VendorCategory {
  None,
  Client,
  Payee,
  Payer,
  SubVendor,
}

export default class PayeeHelper {

  public vendorCategory: VendorCategory;

  public arrErrors: Error[] = [];
  public isLoading: boolean = true;

  public diversityReferenceData: DiversityReferenceData|null = null;
  public diversityReferenceDataLoading: boolean = false;

  public defaultExpenseClasses: ReferenceData|null = null;
  public defaultExpenseClassesLoading: boolean = false;

  public ics: ShortRecord[]|null = null;
  public icsLoading: boolean = false;

  constructor(vendorCategory: VendorCategory) {
    this.vendorCategory = vendorCategory;
  }

  public setIsLoading(): void {
    this.isLoading = (
      this.defaultExpenseClassesLoading
      || this.icsLoading
      || this.diversityReferenceDataLoading
    );
  }

  public populateDataLists(currentOrg: string) {
    this.getDefaultExpenseClasses();
    this.getIndigenousCommunities();
    this.getDiversityReferenceData();
  }

  public getDefaultExpenseClasses() {
    this.defaultExpenseClassesLoading = true;
    getReferenceData('expenseClass').then((response) => {
      this.defaultExpenseClasses = response;
    }).catch((error) => {
      this.arrErrors.push(error);
    }).finally(() => {
      this.defaultExpenseClassesLoading = false;
      this.setIsLoading();
    });
  }

  public getDiversityReferenceData() {
    this.diversityReferenceDataLoading = true;
    getDiversityReferenceData().then((response) => {
      this.diversityReferenceData = response;
    }).catch((error) => {
      this.arrErrors.push(error);
    }).finally(() => {
      this.diversityReferenceDataLoading = false;
      this.setIsLoading();
    });
  }

  public getDiversityReferenceDataCategories(categoryCode: string): DiversityRefDataCategory[] {
    const topLevelRefData: DiversityRefDataValue[] = this.diversityReferenceData ?
      (this.diversityReferenceData as DiversityReferenceData).refData.values.filter((i) => i.categories.find((c) => c.categoryCode === categoryCode)) : [];
    const refData: DiversityRefDataCategory[] = [];
    for (const refDataValues of topLevelRefData) {
      const refDataCategories: DiversityRefDataCategory[]|undefined = refDataValues.categories.filter((x) => x.categoryCode === categoryCode);
      if (refDataCategories !== undefined) {
        for (const refDataCategory of refDataCategories) {
          refData.push(refDataCategory);
        }
      }
    }
    return refData;
  }

  public getIndigenousCommunities(excludeList: string[] = []) {
    if (!this.ics) {
      this.icsLoading = true;
      getShortList('IndigenousCommunity', false, [], false, '', excludeList)
      .then((response) => {
        this.ics = (response as ResourceSearchResult).searchResults.results as ShortRecord[];
      }).catch((error) => {
        this.arrErrors.push(error);
      }).finally(() => {
        this.icsLoading = false;
        this.setIsLoading();
      });
    }
  }

  public isClient(): boolean {
    return this.vendorCategory === VendorCategory.Payer;
  }

  public isSmallEnterprise(diversityDeclarations: DiversityDeclarationsMeta|undefined): boolean {
    return diversityDeclarations === undefined ? false :
    diversityDeclarations.impactStatus ? diversityDeclarations.impactStatus.values.includes('sme') : false;
  }

  public isThirdPartyCertified(diversityDeclarations: DiversityDeclarationsMeta|undefined): boolean {
    return diversityDeclarations === undefined ? false :
      diversityDeclarations.impactSocial ? diversityDeclarations.impactSocial.values.length > 0 : false;
  }

  public isDiverse(diversityDeclarations: DiversityDeclarationsMeta|undefined): boolean {
    return this.isIndigenous(diversityDeclarations) ? false :
      diversityDeclarations === undefined ? false :
        (this.isDiversitySet(diversityDeclarations.socialFocusedOrgs)
          || this.isDiversitySet(diversityDeclarations.impactESG)
        );
  }

  public isIndigenous(diversityDeclarations: DiversityDeclarationsMeta|undefined): boolean {
    return diversityDeclarations === undefined ? false :
      (diversityDeclarations.socialFocusedOrgs === undefined ? false : diversityDeclarations.socialFocusedOrgs.values.includes("indigenousFocused")
      || diversityDeclarations.impactESG === undefined ? false : diversityDeclarations.impactESG.values.includes("indigenousOwned")
      || diversityDeclarations.indigenousImpact === undefined ? false : diversityDeclarations.indigenousImpact.values.includes("indigenousOwned"));
  }

  public getSupplyChainDiversityDescription(diversityMeta: DiversityDeclarationsMeta): string {
    const descriptionValues: string[] = [];
    if (diversityMeta) {
      for (const prop in diversityMeta) {
        if (typeof diversityMeta[prop] === 'object') {
          const diversityDeclarationMeta: DiversityDeclarationMeta = diversityMeta[prop];
          const refDataCategories: DiversityRefDataCategory[] = this.getDiversityReferenceDataCategories(prop);
          for (const diversityValue of diversityDeclarationMeta.values) {
            const refDataCategoryItem: DiversityRefDataCategory|undefined = refDataCategories.find((x) => x.code === diversityValue);
            if (refDataCategoryItem !== undefined) {
              descriptionValues.push(refDataCategoryItem.itemNameForCategory);
            }
          }
        }
      }
    }
    return descriptionValues.join(', ');
  }

  public isDiversitySet(diversity: any|undefined) {
    if (diversity === undefined) {
      return false;
    } else {
      return diversity.values.length > 0;
    }
  }

  public handleError(error: any) {
    handleError(this.arrErrors, error);
  }
}
