














import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class DialogComponent extends Vue {

  private showDialog: boolean = true;

  private closeDialog() {
    this.$emit('close');
  }
}
