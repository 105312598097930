














import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ErrorComponent extends Vue {
  @Prop() private displayErrors: any;
  private clearErrors() {
    this.$emit('clear');
  }

  private displayError(error: any) {
    try {
      const response = error.response || null;
      const errors = response.data ? response.data.errors : null;
      let errorMessage: string = '';
      for (const e of errors) {
        // tslint:disable-next-line
        for (const prop1 in e) {
          for (const prop2 in e[prop1]) {
            if (prop2 === 'message') {
              errorMessage += errorMessage === '' ? '' : ', ';
              errorMessage += e[prop1][prop2];
            }
          }
        }
      }
      return errorMessage || errors || response || error;
    } catch {
      return error;
    }
  }
}
